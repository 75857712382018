<template>
  <div class="swiper_c">
    <swiper
      :autoplay="swiper_option.autoplay"
      :loop="swiper_option.loop"
      :speed="swiper_option.speed"
      :pagination="swiper_option.pagination"
      :space-between="10"
    >
      <swiper-slide
        ><img src="~@/assets/images/banner/banner1.jpg"
      /></swiper-slide>
      <swiper-slide
        ><img src="~@/assets/images/banner/banner2.jpg"
      /></swiper-slide>
      <swiper-slide
        ><img src="~@/assets/images/banner/banner3.jpg"
      /></swiper-slide>
      <swiper-slide
        ><img src="~@/assets/images/banner/banner4.jpg"
      /></swiper-slide>
    </swiper>
  </div>

  <div style="height: 500px" ref="list111">987456</div>
  <br />
  <br />
  <br />
  <br />
  <br />
  <br />
  <br />
  <br />
  <br />
  <br />
  <br />
  <br />
  <br />
  <div style="height: 400px">ddddddddddddddddd</div>
</template>
<script>
import { reactive } from "vue";
// import { createApp } from "vue";

// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from "swiper/vue";

// import Swiper core and required modules
import SwiperCore, { Pagination, Autoplay } from "swiper";

SwiperCore.use([Autoplay, Pagination]);

// Import Swiper styles
import "swiper/swiper.scss";
import "swiper/components/pagination/pagination.scss";

// Import Swiper styles
export default {
  props: {
    // list:{
    //   type:Array,
    //   required:true
    // }
  },
  data() {
    return {
      list: 9,
    };
  },
  components: {
    Swiper,
    SwiperSlide,
  },
  mounted() {
    window.addEventListener("scroll", this.onScroll);
  },
  activated() {
    //进入页面活动时
    // 为 window 对象，绑定 scroll 事件的监听
    //进入活动页面后，监听滚动并把滚动的值放入meta.top 中
    window.addEventListener("scroll", this.scrollHandler);
  },
  methods: {
    onScroll() {
      console.log(123999);
    },
    scrollHandler() {
      console.log(window.scrollY);
      // 注意要拿到组件的meta是 $route,不是$router
      // this.$route.meta.top = window.scrollY; //将滚动位置记录到组件的meta中
    },
  },
  setup() {
    const swiper_option = reactive({
      autoplay: {
        delay: 100000,
        disableOnInteraction: false,
      },
      loop: true,
      speed: 500,
      pagination: {
        clickable: true,
      },
    });
    return {
      swiper_option,
    };
  },
};
</script>

<style >
.swiper_c {
  width: 750px;
  height: 260px;
  overflow: hidden;
}
.swiper_c img {
  width: 100%;
  height: 100%;
}
.swiper-pagination-bullet {
  width: 20px;
  height: 20px;
}
.swiper-pagination-bullet-active {
  background-color: #fff;
}
.swiper-pagination {
  text-align: right;
}
</style>
